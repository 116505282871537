<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card class="primary--border" outlined>
                    <v-card-title class="title">
                        Department
                        <v-spacer></v-spacer>
                        <add-button permission="grade-create"
                                    @action="form.dialog = true,$refs.form ? $refs.form.reset():'' ">New Department
                        </add-button>
                    </v-card-title>

                    <v-data-table
                            :headers="headers"
                            hide-default-footer
                            :items="form.items.data"
                            :options.sync="pagination"
                            :server-items-length="form.items.meta.total"
                            :loading="form.loading">
                        <template v-slot:item="{index, item}">
                            <tr>
                                <td>{{ index + form.items.meta.from }}</td>
                                <td class="text-left">{{ item.title }}</td>
                                <td class="text-center">{{ item.show_order }}</td>
                                <td class="text-center">
                                    <v-chip small label outlined v-if="item.status === 'active'" color="success"> Active</v-chip>
                                    <v-chip small label outlined v-if="item.status === 'inactive'" color="error"> Inactive</v-chip>
                                </td>
                                <td class="text-right">
<!--                                    <view-button permission="section-read" @click.native="viewDepartment(item.id)"/>-->
                                    <edit-button permission="grade-update" @agree="form.edit(item)"/>
                                    <delete-button permission="grade-delete" @agree="form.delete(item.id)"/>
                                </td>
                            </tr>
                        </template>
                        <v-alert slot="no-results" :value="true" color="error" icon="warning">
                            Your search for "{{ search }}" found no results.
                        </v-alert>
                    </v-data-table>

                </v-card>
            </v-flex>
            <v-dialog v-model="form.dialog" persistent max-width="400px">
                <v-card>
                    <v-card-title class="primary white--text">
                        <span class="title">Add/Update</span>
                    </v-card-title>
                    <v-card-text class="pb-1 pt-4">
                        <v-form ref="form"
                                v-model="valid"
                                :lazy-validation="lazy"
                                @submit.prevent="save"
                                @keydown.native="form.errors.clear($event.target.name)" @keyup.enter="save">

                            <v-text-field autocomplete="off" label="Title*" required class="pa-0" outlined dense
                                          v-model="form.title" name="title" :error-messages="form.errors.get('title')"
                                          :rules="[v => !!v || 'Title is required']"/>


                            <v-text-field autocomplete="off" label="Rank*" v-mask="'##'" required
                                          class="pa-0" v-model="form.show_order" name="rank" outlined dense
                                          :error-messages="form.errors.get('rank')"
                                          :rules="[v => !!v || 'Rank is required']"/>

                            <v-select :items="statuses" required class="pa-0" label="Status*" outlined dense
                                      v-model="form.status"
                                      :rules="[v => !!v || 'Name is required']"
                                      :error-messages="form.errors.get('status')"/>

                            <v-textarea outlined dense autocomplete="off" label="Descripiton*"
                                        required class="pa-0 pt-3"
                                        v-model="form.description"
                                        name="title" :error-messages="form.errors.get('description')"/>

                        </v-form>

                        <v-flex xs12>
                            <template>
                                <div style="color:red;">
                                    <v-icon small color="orange">error_outline</v-icon>
                                    <span style="color:orange"> Please Note</span>
                                    <strong></strong>
                                    <p style="line-height: 10px">
                                        <small style="color:#666;font-size: 10px;">
                                            The rank number provided should be a in hierarchical order <br> according to
                                            the grade level.
                                        </small>
                                    </p>
                                </div>
                            </template>
                        </v-flex>

                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="warning" text @click="form.dialog = false, form.reset()">Close</v-btn>
                        <v-btn color="success" text @click="validate">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'
	import Mixins from '@/library/Mixins';

    export default {
		mixins: [Mixins],
        data: () => ({
            valid: true,
            lazy: false,
            isLoading: false,
            co_ordinator: [],
            model: null,
            entries: [],
            form: new Form({
                title: '',
                show_order: '',
                status: '',
                description:'',
                coordinator_id: '',
            }, '/api/department'),
            search: '',
            searchCo: '',
            teacherList: [],

            headers: [
                {text: '#', align: 'left', value: 'id', width: 50, sortable: false},
                {text: 'Name', align: 'left', value: 'name', sortable: false},
                {text: 'Rank', align: 'center', value: 'rank', width: 80},
                {text: 'Status', align: 'center', value: 'rank', width: 110},
                {text: 'Action', align: 'right', sortable: false, width: 200}
            ],
            statuses: [{
                text: 'Active',
                value: 'active'
            }, {
                text: 'Inactive',
                value: 'inactive'
            }
            ],
            chartData: [],
            chartOptions: {
                title: {
                    text: 'Grade-Wise Students',
                    align: 'center'
                },
                legend: {
                    show: false,
                    floating: true,
                },
                labels: []
            },
            rankRule: [
                v => !!v || 'Rank is required',
                v => typeof (v) !== "number" || 'Rank must be valid',
            ],

        }),

        computed: {
            ...mapState(['batch']),
            items() {
                return this.entries.map(entry => {
                    return Object.assign({}, entry, {Name: entry.name})
                })
            }
        },

        watch: {
            'pagination': function () {
                this.get();
            },
            'batch': function (value) {
                this.get();
            },
            'searchCo': function (text) {
                if (!text) return;
                if (text.length < 2) {
                    this.isLoading = false;
                    return;
                }
                if (this.isLoading) return;
                this.searchTeacher(text);
            },
            search(val) {
                if (!val) return;
                if (this.isLoading) return;

                this.isLoading = true;
                this.$rest.get('api/user-search/slim?type=teacher&search=' + val)
                    .then(({data}) => {
                        this.entries = data.data
                    }).catch(err => {
                }).finally(() => (this.isLoading = false))
            }
        },
        methods: {
            validate() {
                if (this.$refs.form.validate()) {
                    this.save()
                }
            },
            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                    this.pagination.totalItems = data.meta.total;
                    this.setChart(data.data);
                })
            },

            searchTeacher(v) {
                this.isLoading = true;
                this.$rest.get('/api/user/slim?search=' + v).then(({data}) => {
                    this.isLoading = false;
                    this.teacherList = data.data.map((teach) => {
                        return {value: teach.id, text: teach.name}
                    });
                });
            },
            save() {
                this.form.batch_id = this.batch.id;
                if (this.form.coordinator_id && Object.keys(this.form.coordinator_id))
                    this.form.coordinator_id = this.form.coordinator_id.id;

                this.form.store();
            },

            setChart(data) {
                if (data == null) {
                    data = this.form.items.data
                }
                this.chartOptions.labels = data.map(item => {
                    return item.name
                });
                this.chartData = data.map((item) => {
                    return item.student_count;
                });
            },

            viewDepartment(id) {
                this.$router.push({
                    name: 'department-detail',
                    params: {
                        departmentId: id
                    }
                })
            }
        }
    }
</script>
<style lang="scss" scoped></style>